<script lang="ts" setup>
  import { computed } from 'vue'
  import { storeToRefs } from 'pinia'
  import { UseBemProps } from '~/composables/Bem/BemFacetOptions'
  import useBem from '~/composables/Bem/Bem'
  import { useNavigationStore } from '~/store/Navigation'
  import BtnNavigation from '~/components/BtnNavigation/BtnNavigation.vue'

  interface Props extends UseBemProps {
    facets?: Array<string>
    btnFacets?: Array<string>
  }

  const props = defineProps<Props>()
  const navigationStore = useNavigationStore()
  const { bemAdd, bemFacets } = useBem('c-action-menu-toggle', props, {})

  const { toggleOpen } = navigationStore
  const { isOpen } = storeToRefs(navigationStore)

  const rootClasses = computed(() => {
    return [bemFacets.value, bemAdd(isOpen ? 'is-open' : '')]
  })

  const requestOpen = () => {
    toggleOpen(!isOpen.value)
  }
</script>

<template>
  <div :class="rootClasses" class="c-action-menu-toggle">
    <div class="c-action-menu-toggle__bar">
      <div class="c-action-menu-toggle__burger">
        <BtnNavigation
          :facets="btnFacets"
          :is-active="isOpen"
          class="c-action-menu-toggle__btn"
          @click="requestOpen"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use '@nirazul/scss-utils' as utils;
  @use '/assets/scss/util/color/color' as col;
  @use '/assets/scss/util/transition' as trs;
  @use '/assets/scss/util/layer' as layer;
  @use '/components/NavigationGame/NavigationGame' as NavigationGame;

  $padding: 0;

  .c-action-menu-toggle {
    @include utils.overlay(fixed);
    pointer-events: none;
    z-index: layer.z-index('action-menu-toggle');
    padding-right: var(--scroll-lock);
  }

  .c-action-menu-toggle__bar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    width: 100%;
    height: NavigationGame.$height;
  }

  .c-action-menu-toggle__burger {
    width: NavigationGame.$height;
    height: NavigationGame.$height;
    padding: $padding;
  }

  .c-action-menu-toggle__btn {
    pointer-events: auto;
  }

  .c-action-menu-toggle__btn-text {
    @include utils.hide-visually;
  }
</style>
